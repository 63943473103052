// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import { Application } from "@hotwired/stimulus"
const application = Application.start()

import {Turbo} from "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./controllers"


import "./src/jquery-panda"


//import "./src/jquery-3.6.0.min"
//import "./src/modernizr.min"


import "./src/bootstrap.bundle.min"
import "./src/imagesloaded.pkgd.min"
import "./src/jquery.magnific-popup.min"
//import "./src/isotope.pkgd.min"
import "./src/jquery.appear.min"
import "./src/jquery.easing.min"
import "./src/owl.carousel.min"

import "./src/counter-up"
//import "./src/masonry.pkgd.min"
import "./src/jquery.nice-select.min"
import "./src/jquery-ui.min"
import "./src/wow.min"
import "./src/countdown.min"

import "./src/main"

